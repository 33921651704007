import { ChangeDetectionStrategy, Component } from '@angular/core';
import { of, combineLatest, switchMap } from 'rxjs';
import { ToolkitMetadataService } from '../services/metadata.service';

@Component({
  selector: 'itk-toolkit-settings',
  styleUrl: './settings.component.scss',
  templateUrl: './settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItkSettingsComponent {
  constructor(private readonly metadataService: ToolkitMetadataService) {}

  private readonly settingsRoutes$ = this.metadataService.currentToolkit$.pipe(
    switchMap((toolkit) => {
      return of({
        lite: `${toolkit.slug}/settings/lite`,
        customization: `${toolkit.slug}/settings/customization`,
      });
    }),
  );

  public readonly vm$ = combineLatest({ settingsRoutes: this.settingsRoutes$ });
}
