<ng-container *ngIf="vm$ | async as vm">
  <div class="flex flex-row p-4">
    <itk-button
      class="mr-6"
      [routerLink]="vm.settingsRoutes.lite"
      routerLinkActive
      #rla1="routerLinkActive"
      [color]="rla1.isActive ? 'primary' : 'white'"
      size="2xl"
    >
      Lite
    </itk-button>

    <itk-button
      [routerLink]="vm.settingsRoutes.customization"
      routerLinkActive
      #rla2="routerLinkActive"
      [color]="rla2.isActive ? 'primary' : 'white'"
      size="2xl"
      >Customization
    </itk-button>
  </div>
</ng-container>
<router-outlet></router-outlet>
